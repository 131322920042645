import styled from "styled-components";
import { respondTo } from "../../styles/respondTo";
import { defaultTheme } from "../../styles/theme";

export const Wrapper404 = styled.div`
  width: 100vw;
  height: 100vh;
  background-image: url(${props => props.img});
  background-color: ${defaultTheme.colors.black};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Content404 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 90%;
  z-index: 1;
  padding-top: 4%;
  max-width: 80vw;
`;

export const ContentBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 30%;
  max-width: 80vw;
`;

export const BackButton = styled.button`
  display: flex;
  align-items: center;
  background: transparent;
  cursor: pointer;
  height: 28px;
  border: 0px;

  @media (min-width: 1200px) {
    margin-top: 2rem;
  }
`;
