import * as React from "react";
import { Wrapper404, Content404, ContentBox, BackButton } from "../components/404/Page404.styled";

import { Text, Title } from "../components/UI";

import { defaultTheme } from "../styles/theme";
import { GlobalStyles } from "../styles/GlobalStyles";
import { navigate } from "gatsby";
import { useText } from "../common/hooks";
import Metadata from "../components/layout/MetaData";

const error404 = require("../assets/images/newWebsite/planets_404.svg");

const Page404 = () => {
  const { text } = useText();

  return (
    <Wrapper404 img={error404}>
      {/* <Logo src={logo} /> */}
      <Metadata title="404" />
      <GlobalStyles />

      <Content404>
        <Title size="header" color={defaultTheme.colors.white}>
          {text("404.title")}
        </Title>
        <ContentBox>
          <Text size="large" color={defaultTheme.colors.white} align="center">
            {text("404.text")}
          </Text>
          <BackButton onClick={() => navigate("/")}>
            <Text size="large" color={defaultTheme.colors.geeksGreen} align="center">
              {text("404.button")}
            </Text>
          </BackButton>
        </ContentBox>
      </Content404>
    </Wrapper404>
  );
};

export default Page404;
